<template>
  <div class="estate-album base-container">
    <div class="header-mol">
      <div class="info">
        <h3 class="title">相册管理</h3>
        <p class="summary">请按照预设相册类别，创建和管理相关相册图片；</p>
      </div>
    </div>
    <el-collapse accordion class="collapse" v-model="collapseActive">
      <el-collapse-item
        v-for="(item, index) of albumImages"
        :name="index"
        :key="item.imageType"
      >
        <h2 class="title" slot="title">
          <i class="el-icon-picture"></i>{{ item.imageTypeName }}（{{
            item.viewImageList.length
          }}）
        </h2>
        <f-upload-extend
          class="mt20"
          ref="uploader"
          :limit-size="5"
          :default-file-list="item.viewImageList"
          :draggable="true"
          @onSuccess="fileList => uploadSuccess(fileList, item)"
          @remove="target => handleImgRemove(target, item.imageList)"
          @drag="fileList => handleImgDrag(fileList, item)"
        ></f-upload-extend>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import {
  getEstateAlbumImages,
  deleteEstateAlbumImage,
  addEstateAlbumImages,
  editEstateAlbumImages
} from "@/api/estate";
import FUploadExtend from "@/components/upload/extend";
export default {
  components: {
    FUploadExtend
  },
  data() {
    return {
      albumImages: [],
      collapseActive: 0 // 默认展开第一个
    };
  },
  created() {
    this.getEstateAlbumImages();
  },
  methods: {
    // 删除相册图片
    async handleImgRemove(target, list) {
      const targetId = list.find(item => item.imagePath === target).id;
      const res = await deleteEstateAlbumImage({ id: targetId });
    },
    // 上传相册图片
    async uploadSuccess(fileList, { id, imageType }) {
      await addEstateAlbumImages({
        imagePathList: fileList,
        estateId: this.$estateInfo.estateId,
        id,
        imageType
      });
      this.getEstateAlbumImages();
    },
    // 获取相册列表
    async getEstateAlbumImages() {
      const res = await getEstateAlbumImages({
        estateId: this.$estateInfo.estateId
      });
      if (res) {
        this.albumImages = res.map(item => {
          item.viewImageList = item.imageList.map(item => item.imagePath);
          return item;
        });
      }
    },
    // 拖动排序
    handleImgDrag(fileList, { id, imageType }) {
      editEstateAlbumImages({
        imagePathList: fileList,
        estateId: this.$estateInfo.estateId,
        id,
        imageType
      });
    }
  }
};
</script>

<style scoped lang="scss">
.estate-album {
  .collapse {
    margin-top: 40px;
    .title {
      padding-left: 10px;
      font-size: 16px;
      i {
        font-size: 18px;
        margin-right: 10px;
        color: #999;
      }
    }
    ::v-deep {
      .el-collapse-item__header {
        background: #f5f5f5;
      }
    }
  }
}
</style>
