var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "estate-album base-container"
  }, [_vm._m(0), _c('el-collapse', {
    staticClass: "collapse",
    attrs: {
      "accordion": ""
    },
    model: {
      value: _vm.collapseActive,
      callback: function callback($$v) {
        _vm.collapseActive = $$v;
      },
      expression: "collapseActive"
    }
  }, _vm._l(_vm.albumImages, function (item, index) {
    return _c('el-collapse-item', {
      key: item.imageType,
      attrs: {
        "name": index
      }
    }, [_c('h2', {
      staticClass: "title",
      attrs: {
        "slot": "title"
      },
      slot: "title"
    }, [_c('i', {
      staticClass: "el-icon-picture"
    }), _vm._v(_vm._s(item.imageTypeName) + "（" + _vm._s(item.viewImageList.length) + "） ")]), _c('f-upload-extend', {
      ref: "uploader",
      refInFor: true,
      staticClass: "mt20",
      attrs: {
        "limit-size": 5,
        "default-file-list": item.viewImageList,
        "draggable": true
      },
      on: {
        "onSuccess": function onSuccess(fileList) {
          return _vm.uploadSuccess(fileList, item);
        },
        "remove": function remove(target) {
          return _vm.handleImgRemove(target, item.imageList);
        },
        "drag": function drag(fileList) {
          return _vm.handleImgDrag(fileList, item);
        }
      }
    })], 1);
  }), 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "header-mol"
  }, [_c('div', {
    staticClass: "info"
  }, [_c('h3', {
    staticClass: "title"
  }, [_vm._v("相册管理")]), _c('p', {
    staticClass: "summary"
  }, [_vm._v("请按照预设相册类别，创建和管理相关相册图片；")])])]);

}]

export { render, staticRenderFns }